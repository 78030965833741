import React, { useState, useMemo } from 'react'
import Filter from '../../context/Filter'
import List from 'context/List'
import { withReload } from '../../context/Reload'
import { withSharedStorage } from '../../context/SharedStorage'
import Fetch from '../../components/Fetch'
import Columns, { ColumnsContext } from '../../context/Columns'
import T, { ColumnsSelector, Pager, ExportTable } from '../../components/Table'
import ReloadButton from '../../components/ReloadButton'
import Filters from './_Filters'
import Add from './Add'
import Controls, { ToggleControlsButton } from './Controls'

import {
  FiFilter,
  FiBell,
  FiBellOff
} from 'react-icons/fi'

const Table = ( {
  client,
  schema,
  withReload: { reload },
  withSharedStorage: { storage: { params }, updateStorege }
} ) => {
  const [ count ] = useState( 0 )
  const [ query, setQuery ] = useState( {} )
  const [ isUnnotified, setIsUnnotified ] = useState( false )
  const [ isFiltersOpened, setIsFiltersOpened ] = useState( false )

  useMemo( () => {
    updateStorege( { client, schema } )
  }, [ count ] )

  useMemo( () => {
    if ( isUnnotified )
      return updateStorege( { params: { EstadoDesc: null } } )

    updateStorege( { params: {} } )
  }, [ isUnnotified ] )

  const PREFIX = `/api/dataSource/${ client.name }/${ schema.name }`

  function toggleFilters() {
    setIsFiltersOpened( ! isFiltersOpened )
  }

  const toggleUnnotified = () => {
    setIsUnnotified( ! isUnnotified )
  }

  if ( ! schema )
    return

  return (
    <List><Columns
      endpoint={ `${ PREFIX }/columns` }
    >
      <Filter>
        <div className="title">
          <h1>{ schema.name }</h1>

          <div className="actions">

            <ToggleControlsButton />

            <Add
              client={ client }
              schema={ schema }
            />

            <button title="toggle unnotified" onClick={ toggleUnnotified }>
              { isUnnotified ? <FiBell /> : <FiBellOff /> }
            </button>

            <ExportTable
              action={ {
                title: 'exportar tabla',
                icon: 'HiOutlineDocumentReport',
                endpoint: `${ PREFIX }/export`,
                useFilter: 1,
                prefix: `${ schema.name }-`
              } }
            />

            <button title="filtrar" onClick={ toggleFilters } >
              <FiFilter />
            </button>

            <ReloadButton />

            <ColumnsSelector
              action={ {
                title: 'seleccionar columnas',
                icon: 'FiColumns'
              } }
            />
          </div>
        </div>

        <Controls />

        <ColumnsContext.Consumer>
          { context => ( <>
            <div className="hs one">
              <Fetch
                type="table"
                endpoint={ `${ PREFIX }/list` }
                query={ query }
                params={ params }
                useFilter={ true }
              >
                { ( data, update ) => (
                  <div className="hs card table">
                    <T
                      data={ data.page }
                      columns={ context.columns }
                      prefix={ PREFIX }
                      refresh={ reload }
                      actions={ [
                        {
                          icon: 'FiPlus',
                          title: 'clone',
                          modal: 'CloneReport',
                        },
                        {
                          icon: 'FiSend',
                          title: 'notify',
                          modal: 'NotifyByRow',
                        },
                        {
                          icon: 'TbWorldWww',
                          title: 'notify all in domain',
                          modal: 'NotifyByDomain'
                        },
                        {
                          icon: 'RiForbid2Line',
                          title: 'Rechazar',
                          modal: 'RejectByRow'
                        }
                      ] }
                    />

                    { data.page.length !== 0 ? (
                      <Pager
                        data={ data }
                        updateParams={ setQuery }
                      />
                    ) : null }
                  </div>
                ) }
              </Fetch>
            </div>

            { isFiltersOpened ? <Filters
              close={ toggleFilters }
            /> : null }
          </> ) }
        </ColumnsContext.Consumer>
      </Filter>
    </Columns></List>
  )
}

export default withSharedStorage( withReload( Table ) )
