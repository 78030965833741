import { useState, useEffect } from 'react'
import { withSharedStorage } from 'context/SharedStorage'
import { withReload } from 'context/Reload'
import http from 'http'
import Placeholder from './_Placeholder'
import ContactForm from './_ContactForm'

import { FiX, FiSettings, FiPlus, FiSend, FiTrash2, FiMinus } from 'react-icons/fi'
import { BsSendExclamation } from 'react-icons/bs'

const ContactList = ( {
  domain,
  send = () => {},
  close = () => {},
  force = false,
  withSharedStorage: { storage: { client, schema } },
  withReload: { reload }
} ) => {
  const [ site, setSite ] = useState( {
    domain: '',
    contactList: []
  } )
  const [ isFormOpen, setIsFormOpen ] = useState( false )
  const [ isAdmin, setIsAdmin ] = useState( false )
  const [ updatedCount, setUpdatedCount ] = useState( 0 )

  useEffect( () => {
    http
      .get( `/api/proxy/${ client.name }/contact`, { params: {
        __raw: true,
        __select: 'contactList domain',
        domain
      } } )
      .then( ( { data } ) => {
        if ( data.length !== 0 )
          return setSite( { ...site, ...data[ 0 ] } )

        http
          .post( `/api/proxy/${ client.name }/contact`, {
            domain
          } )
          .then( ( { data: newSite } ) => {
            setSite( newSite )
          } )

        console.log( '404', domain )
      } )
  }, [ domain, updatedCount ] )

  const toggleForm = () => {
    setIsFormOpen( ! isFormOpen )
  }

  const toggleAdmin = () => {
    setIsAdmin( ! isAdmin )
  }

  const refresh = () => {
    setUpdatedCount( updatedCount + 1 )
  }

  // const send = ( contact ) => {
  //   http
  //     .post( `/api/proxy/${ client.name }/contact/sendEmailByDomain`, {
  //       schema: schema.name,
  //       domain,
  //       to: contact.email
  //     } )
  //     .then( ( { data } ) => {
  //       console.log( { send: data } )
  //       reload()
  //     } )
  // }

  const remove = ( contact ) => {
    const contactList = site.contactList.filter( c => c._id !== contact._id )

    http
      .put( `/api/proxy/${ client.name }/contact/${ site._id }`, {
        contactList
      } )
      .then( ( { data } ) => {
        setSite( data )
      } )
  }

  return <div className="modal x50">
    <main>
      <header>
        <h1>contact list</h1>

        <div className="actions">
          <button onClick={ toggleForm }>
            { isFormOpen ? <FiMinus /> : <FiPlus /> }
          </button>

          <button onClick={ toggleAdmin }>
            <FiSettings />
          </button>

          <button onClick={ close }>
            <FiX />
          </button>
        </div>
      </header>

      <section className="hs gap padding">

        { isFormOpen ? <ContactForm
          site={ site }
          close={ toggleForm }
          refresh={ refresh }
        /> : null }

        { site.contactList.length === 0 ? <Placeholder domain={ domain } /> : <table>
          <thead>
            <tr>
              <td>email</td>
              <td>priority</td>
              <td className={ `actions x${ isAdmin ? 2 : 1 }` }></td>
            </tr>
          </thead>

          <tbody>
            { site.contactList
              .sort( ( a, b ) => a.priority - b.priority )
              .map( ( c, index ) => <tr
                key={ index }
              >
                <td>{ c.email }</td>
                <td>{ c.priority }</td>
                <td className="actions">
                  <button
                    title="send"
                    onClick={ () => send( c ) }
                  >
                    <FiSend />
                  </button>

                  { force ? <button
                    title="force send"
                    onClick={ () => send( c, true ) }
                  >
                    <BsSendExclamation />
                  </button> : null }

                  { isAdmin ? <button onClick={ () => remove( c ) }>
                    <FiTrash2 />
                  </button> : null }
                </td>
              </tr> )
            }
          </tbody>
        </table> }
      </section>
    </main>
  </div>
}

export default withReload( withSharedStorage( ContactList ) )
